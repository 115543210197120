import React from 'react';
// import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick"

import content from '../../content/screenshots.yaml'

const screenshots = content.screenshots;

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <img className={className} onClick={onClick} alt="right arrow" src="images/ppl-right-arrow.png" />
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <img className={className} onClick={onClick} alt="left arrow" src="images/ppl-left-arrow.png" />
  );
};

const PeopleProfiles = (props) => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    adaptiveHeight: false,
    autoplay: false,
    draggable: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: i => (
      <button>
        {i + 1}
      </button>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },        {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ],
  };

  return (
    <div id="people-profiles-slider" style={{ textAlign: 'center' }}>
      <Slider {...settings} style={{ margin: '10%' }}>{props.children}</Slider>
    </div>
  );
};

export default () => {

  library.add(fas)
  return (
    <section id="screenshots">
      <div className="animated fadeIn">
        <div className="row section-head">
          <h1>{ content.title }</h1>
          {/* <p>{ content.body }</p> */}
        </div>

        <div className="row">
          <div className="pricing-tables bgrid-quarters s-bgrid-halves">
            {/* <PeopleProfiles>  */}
            <div id="logos-flex-box">
              { screenshots.map((plan) =>
                <div className="column">
                  {/* <div className="price-block"> */}
                    <a href={ plan.caption } target="_blank" rel="noreferrer">
                        <img className="align-center" src={ plan.thumb } alt={ plan.caption } style={ plan.imgStyle }></img>
                    </a>                  
                  {/* </div> */}
                </div>
              )}
            </div>
            {/* </PeopleProfiles> */}
          </div>
        </div>
        <div className="buttons align-center">
        <a className="button learn-more" id="work-with-us-on-upwork-button" href={ content.button2.to } target="_blank" rel="noreferrer">{ content.button2.label }</a>
      </div>
      </div>
    </section>
  )
}