import React from "react"
// import ScrollAnimation from 'react-animate-on-scroll'
import content from "../../content/services.yaml"

export default () => (

  <section id='services'>
    {/* {content.map((value, index) => {

      let pos, imagepos
      if (index%2 === 0){
        pos = "right"
        imagepos = "left"
      }else{
        pos = "left"
        imagepos = "right"
      }

      let media
      if (value.image){
        media = ( <img src={ value.image } alt="" /> )
      } else if (value.vimeo) {
        media = (
            <div className="fluid-video-wrapper">
              <iframe
                title="video"
                src={ 'https://player.vimeo.com/video/' + value.vimeo + '?title=0&amp;byline=0&amp;portrait=0&amp;color=F64B39'}
                width="500" height="281" frameBorder="0" webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                >
              </iframe>
            </div>
        )
      }

      return(
        <div className={ 'row feature ' + value.name }>
          <div className={ 'six columns ' + pos }>
            <h3>{ value.title }</h3>
            <p>{ value.body }</p>
          </div>

          <ScrollAnimation
            animateIn="pulse"
            animateOnce={true}
            initiallyVisible={true}
            className={ 'six columns feature-media ' + imagepos }
            >
            { media }
          </ScrollAnimation>
       </div>
     )
   })} */}
   <div className="animated fadeIn">
      <div className="row section-head">
        <h1>{ content.title }</h1>
      </div>
      <div className="row">
        <div className="pricing-tables bgrid-quarters s-bgrid-halves">
          {/* <PeopleProfiles>  */}
            { 
            content.plans.map((plan, index) =>
                <div className="column">
                  <div className="price-block">
                    {/* <a href={ plan.signup.to } target="_blank" rel="noreferrer"> */}
                      <h3 className="services-title">
                        {/* <i className="fa"><FontAwesomeIcon icon={ plan.fa } /></i> */}
                        {/* <img src={ plan.pic } alt="Profile"></img> */}
                        { plan.title }
                      </h3>
                      {/* <p className="plan-price">
                        { plan.price }
                        <span>{ plan.per }</span>
                      </p> */}

                      <ul className="features">
                        { plan.features.map((item, index) =>
                          <li><img style={ item.imgStyle } src={ item.logo } alt={ item.name } /></li>
                        )}
                      </ul>
                      {/* <p className="plan-features">{ plan.features }</p>                       */}
                      {/* <footer className="plan-sign-up">
                        <Link className="button" to={ plan.signup.to }>
                          { plan.signup.label }
                        </Link>
                      </footer> */}
                    {/* </a> */}
                  </div>
                </div>
            )
            }
          {/* </PeopleProfiles> */}
        </div>
      </div>
      <div className="buttons margin-top-40 align-center">
        <a className="button learn-more" id="work-with-us-on-upwork-button" href={ content.button2.to } target="_blank" rel="noreferrer">{ content.button2.label }</a>
      </div>  
    </div>  

  </section>
)
