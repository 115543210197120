import React, { useState, useEffect } from "react"
// import { Link } from "gatsby"
import content from "../../content/pricing.yaml"

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick"

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    // <div 
    // className={className}
    // style={{ ...style, display: 'block', background: 'black'}}
    // onClick={onClick}
    // >
    // </div>
    <img className={className} onClick={onClick} alt="right arrow" src="images/ppl-right-arrow.png" />
  );
}

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    // <div 
    // className={className}
    // style={{ ...style, display: 'block', background: 'black'}}
    // onClick={onClick}
    // >
    // </div>
    <img className={className} onClick={onClick} alt="left arrow" src="images/ppl-left-arrow.png" />
  );
}


const PeopleProfiles = (props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    adaptiveHeight: false,
    autoplay: false,
    // autoplaySpeed: 7000,
    draggable: false,
    // appendDots: dots => (
    //   <ol> {dots} </ol>
    // ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: i => (
      <button>
        {i + 1}
      </button>
    ),
  } 
  const getScreenWidth = () => {
    if (typeof window !== `undefined`) {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }
  };

  let [displaySlider, setDisplaySlider] = useState(undefined);
  useEffect(() => {
    setDisplaySlider(getScreenWidth() > 1150);
    let timeoutId = null;

    // debounce resize by 15ms
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
      setDisplaySlider(getScreenWidth() > 1150);
      }, 15);
    }
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  if (displaySlider) {
    return (
      <div id="people-profiles-slider" className="pricing-tables bgrid-quarters s-bgrid-halves">
        <Slider {...settings}>
          {props.children}
        </Slider>
      </div>
    );
  }

  return (
    <div id="people-flex-box">
      {props.children}
    </div>
  );
}

export default () => {

  library.add(fas)

  return (
    <section id="pricing">
      <div className="animated fadeIn">
        <div className="row section-head">
          <h1>{ content.title }</h1>
          <p>{ content.body }</p>
        </div>

        <div className="row">
          <div className="pricing-tables bgrid-quarters s-bgrid-halves">
            <PeopleProfiles> 
              { content.plans.map((plan, index) =>
                  <div className="column">
                    <div className="price-block">
                      <a href={ plan.signup.to } target="_blank" rel="noreferrer">
                        <h3 className="plan-title">
                          {/* <i className="fa"><FontAwesomeIcon icon={ plan.fa } /></i> */}
                          <img src={ plan.pic } alt="Profile"></img>
                          <p className="plan-price">
                            { plan.price }
                            <span>{ plan.per }</span>
                          </p>
                          { plan.title }
                        </h3>
                        {/* <p className="plan-price">
                          { plan.price }
                          <span>{ plan.per }</span>
                        </p> */}

                        {/* <ul className="features">
                          { plan.features.map((value, index) =>
                            <li>{ value }</li>
                          )}
                        </ul> */}
                        <p className="plan-features">{ plan.features }</p>                      
                        {/* <footer className="plan-sign-up">
                          <Link className="button" to={ plan.signup.to }>
                            { plan.signup.label }
                          </Link>
                        </footer> */}
                      </a>                  
                    </div>
                  </div>
              )
              }
            </PeopleProfiles>
          </div>
        </div>
        <div className="buttons margin-top-40">
          <a className="button learn-more" id="work-with-us-on-upwork-button" href={ content.button2.to } target="_blank" rel="noreferrer">{ content.button2.label }</a>
        </div>
      </div>
    </section>
  )
}
